﻿/* eslint-disable */
/* Startup file for v4 apps.  Keep code to minimum */
'use strict';

function IX_GetCookieValue(name) {
    name += "=";
    var ca = document.cookie.split(";");
    var cookieVal;
    for (var i = 0; i < ca.length; i++) {
        var cookie = ca[i].trim();
        if (cookie.indexOf(name) == 0)
            cookieVal = cookie.substring(name.length, cookie.length);
    }
    if (cookieVal == null)
        return null;
    return escape(cookieVal);
}

function IX_IsHTTPS() {
    return location.protocol === 'https:';
}

function IX_GetSecureFlag() {
    return IX_IsHTTPS() ? ";secure" : "";
}

function IX_SetCookieValue(name, value, expirationOffset) {
    if (typeof expirationOffset != "undefined") {
        if (expirationOffset == "Session") {
            document.cookie = name + "=" + value + IX_GetSecureFlag() + ";expires=Session;path=/";
        } else {
            var date = new Date();
            date.setDate(date.getDate() + 2);
            document.cookie = name + "=" + value + IX_GetSecureFlag() + ";expires=" + date.toUTCString() + ";path=/";
        }
    } else {
        document.cookie = name + "=" + value + IX_GetSecureFlag() + ";path=/";
    }
}

function IX_isMacOS() {
    var isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    return isMac;
}

function IX_isIOS() {
    var ua = navigator.userAgent;
    var isiOS = /iPad/i.test(ua) || /iPhone/i.test(ua) || /iPhone/i.test(ua) ||
        (/Mac/i.test(ua) && "ontouchend" in document); // added to detect ipad on ios 13+ 
    // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
    return isiOS;
}

function IX_isAndroid() {
    var ua = navigator.userAgent;
    var isAndroid = /android/i.test(ua);
    return isAndroid;
}

// Redirects AngularJS hasbang route to html5 Angular route
function IX_ICUIRedirect() {
    if (location.href.toLowerCase().indexOf('wf_app.aspx') > -1) {
        var allParams = {};
        var rewrittenUrl = '';
        var queryParams = location.search.substr(1).split("&");

        if (location.search.toLowerCase().indexOf('?') > -1) {
            if (queryParams.length) {
                queryParams.forEach(function (item) { allParams[item.split("=")[0]] = item.split("=")[1] });
            }
        }
        if (location.hash.toLowerCase().indexOf('#') > -1) {
            var hashParams = location.hash.substring(location.hash.indexOf('?') + 1).split("&");

            if (hashParams.length) {
                hashParams.forEach(function (item) { allParams[item.split("=")[0]] = item.split("=")[1] });
            }
        }

        if (location.hash.indexOf('#!/w/') > -1) {
            var pathWithWorkflow = location.hash.substring(location.hash.indexOf('#!/w/') + 5).split('?');
            if (pathWithWorkflow.length > 0) {
                rewrittenUrl = pathWithWorkflow[0] + '/';
            }
        } else if (location.href.toLowerCase().indexOf('/apps/') > -1) {
            rewrittenUrl = (location.href.substring(location.href.toLowerCase().indexOf('/apps/') + 6, location.href.toLowerCase().indexOf('app.aspx') + 3)).toLowerCase().replace(/_/gi, '') + '/';
        }

        if (allParams["s"]) {
            rewrittenUrl = rewrittenUrl + allParams["s"] + '/';
            delete allParams["s"];
        }

        if (Object.keys(allParams).length) {
            rewrittenUrl = rewrittenUrl + '?' + Object.keys(allParams).map(function (key) { return key + '=' + allParams[key]; }).join("&");
        }
        location.href = location.protocol + '//' + location.host + '/ui/' + rewrittenUrl;
    }
}

function IC_UrlDynamicReplacementGoTo(element, e) {
    var parentSelector = '[ic-replace-value],[ic-replace-value-trusted]';
    var parentElement = $(element).parents(parentSelector).get(0);
    if (parentElement == null) return;
    var targetId = element.getAttribute('data-href');
    var targetSelector = '[id*="' + targetId + '" i]';
    var target = parentElement.querySelector(targetSelector);
    if (target == null) return;
    target.scrollIntoView();
    return;
}

if (typeof module != 'undefined') {
    module.exports = {
        IX_isAndroid: IX_isAndroid,
        IX_isIOS: IX_isIOS,
        IX_isMacOS: IX_isMacOS,
        IX_SetCookieValue: IX_SetCookieValue,
        IX_GetCookieValue: IX_GetCookieValue,
        IX_ICUIRedirect: IX_ICUIRedirect,
        IC_UrlDynamicReplacementGoTo: IC_UrlDynamicReplacementGoTo
    };
}